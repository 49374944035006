<template>
  <div class="app">
    <!-- 选择资料名称弹窗 -->
    <el-dialog
      v-model="infoVisible"
      title="选择资料"
      width="340"
      :close-on-click-modal="false"
      align-center
    >
      <div>
        <el-tree
          ref="treeRef"
          style="max-width: 600px"
          :data="data"
          show-checkbox
          default-expand-all
          node-key="id"
          @check-change="handleCheckChange"
        />
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="infoVisible = false" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="save">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 外勤管理 -->
    <div>
      <el-dialog
        v-model="infoTaskVisible"
        title="选择资料"
        width="590"
        :close-on-click-modal="false"
        align-center
      >
        <el-form :model="stepForm" :rules="rules" ref="stepForm" label-width="80px" v-if="type == 'step'">
          <el-form-item label="步骤名称:" prop="stepName">
            <el-input v-model="stepForm.stepName"  style="width: 450px;" size="small" />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="stepForm.remark" :rows="2" type="textarea" size="small" style="width: 450px;" clearable />
          </el-form-item>
        </el-form>
        <div style="display: flex;">
          <div class="content">
            <div class="head">
              资料类型
            </div>
            <div style="padding: 10px;">
              <el-input
                v-model="filterText"
                style="width: 200px"
                size="small"
                placeholder="Filter keyword"
              />

              <el-tree
                ref="treeRef"
                style="max-width: 600px"
                :data="data"
                show-checkbox
                default-expand-all
                :props="defaultProps"
                node-key="id"
                :filter-node-method="filterNode"
                default-checked-keys
                @check-change="handleCheckChange('task')"
              />
            </div>
          </div>
          <div class="content">
            <div class="head">
              已选类型
            </div>
            <div class="selContent">
              <div v-for="item in selectTaskList" style="margin: 3px;">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="infoTaskVisible = false" size="small">取消</el-button>
            <el-button type="warning"  size="small" @click="delStep" v-if="type == 'step'">
              删除步骤
            </el-button>
            <el-button type="primary"  size="small" @click="save(type)">
              确定
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { contractInformation,stepDel,stepUpdate } from "@/api/newCrm.js";

export default {
  name: '',
  components: {},
  props: {},
  data () {
    return {
      infoVisible: false,//资料名称弹窗
      infoTaskVisible:false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      data: [],
      selectTaskList:[],//任务外勤选中的select
      filterText: '',
      ids:[],
      selectedChildren: [],
      taskIndex: null,
      type: '',
      stepForm:{},
      rules:{
        stepName: [
          { required: true, message: '请填写步骤名称', trigger: 'blur' }
        ],
      }
    }
  },
  computed: {},
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    }
  },
  created () {
    contractInformation().then(res => {
      if(res.data.msg == 'success'){
        this.data = res.data.data.data;
      }
    });
  },
  mounted () {},
  methods: {
    // 初始化
    init(type,val){
      this.type = type;
      if(type == 'task'){
        this.infoTaskVisible = true;
        this.$nextTick(()=>{
          this.$refs.treeRef.setCheckedKeys([7,11],true)
        })
        this.taskIndex = val*1;
      }else if(type == 'step'){
        this.infoTaskVisible = true;
        console.log(val);
        this.stepForm = Object.assign({},val);
        let temp = this.stepForm.infos.split(',').map(Number)
        console.log(temp);
        this.$nextTick(()=>{
          this.$refs.treeRef.setCheckedKeys(temp,true)
        })
      }else{
        this.infoVisible = true;
        this.selectedChildren = [];
      }
    },

    filterNode(val, data) {
      if (!val) return true;
      return data.label.indexOf(val) !== -1;
    },

    // tree操作
    handleCheckChange(type) {
      if(type == 'task'){
        let tempList = this.$refs.treeRef.getCheckedNodes(true,true)
        this.selectTaskList = []
        tempList.forEach((val,i) => {
          this.selectTaskList.push(val)
        });
      }else{
        this.selectedChildren = this.$refs.treeRef.getCheckedNodes(true,true)
        this.ids = this.$refs.treeRef.getCheckedKeys(true)
      } 
    },  

    // 确定操作
    save(type){
      if(type == 'task'){
        this.infoTaskVisible = false;
        this.$emit('success',this.selectTaskList,this.taskIndex)
      }else if(type == 'step'){
        this.$refs['stepForm'].validate((valid) => {
          if (valid) {
            stepUpdate(this.stepForm).then(res => {
              if(res.data.msg =='success'){
                this.$message.success('保存成功')
                this.infoTaskVisible = false;
                this.$emit('success')
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }else{
        this.infoVisible = false;
        this.$emit('success',this.selectedChildren)
      }
    },

    // 删除步骤
    delStep(){
      stepDel({flowStepIds:[this.stepForm.id]}).then(res => {
        if(res.data.msg =='success'){
          this.$message.success('删除成功')
          this.infoTaskVisible = false;
          this.$emit('success')
        }else{
          this.$message.error('删除失败')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content{
  width: 300px;
  height: 460px;
  border: 1px solid #D7D7D7;
  overflow-y: auto;
  box-sizing: border-box;
  margin: 0 20px;
  border-radius: 6px;
}
.head{
  height: 40px;
  background-color: #F5F7FA;
  padding: 8px;
  line-height: 24px;
  border-bottom: 1px solid #D7D7D7;
}
.selContent{
  padding: 10px 20px;
}
</style>
