<template>
  <!-- 新增银行账户 -->
  <el-dialog :title="comName ? comName : '设置账户'" v-model="dialogVisible" width="505px" :before-close="handleClose">
    <el-form :model="form" :rules="rules" ref="ruleForm" size="small" label-width="110px">
      <el-form-item label="账户简称：" prop="name">
        <input-common v-model:modelValue="form.name" placeholder="请输入账户简称" style="width: 340px"></input-common>
      </el-form-item>
      <el-form-item label="币种：">
        <el-select filterable v-model="form.currency" placeholder="请选择" style="width: 340px; margin: 0">
          <el-option v-for="item in wbOptions" :label="item.type" :value="item.code" :key="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="对应科目：" prop="ids">
        <el-select v-model="form.ids" value-key="id" placeholder="请选择" style="width: 340px; margin: 0">
          <el-option v-for="item in subjectOptions" :key="item.id" :label="item.name3" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="银行主账号：" prop="bankNo">
        <input-common v-model:modelValue="form.bankNo" placeholder="请输入银行主账号" style="width: 340px"></input-common>
        <div style="font-size: 12px;display: flex;align-items: center;color: red;"><el-icon style="margin-right: 3px;">
            <Warning />
          </el-icon>此账号用于识别导入的银行回单、内部转账流水，请准确填写</div>
      </el-form-item>
      <span class="sq_set" @click="elCheck">授权设置</span>
      <div v-if="checkDisabled">
        <el-form-item label="授权银行:">
          <el-select v-model="form.yhName" placeholder="请选择" style="width: 340px; margin: 0" filterable>
            <el-option v-for="item in bankBankList" :key="item.value" :label="item.label" :value="item.value">
              <span><i :class="item.icon"></i>{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="登录方式:" v-if="form.yhName == '交通银行'" prop="bankLoginMethod">
          <el-select v-model="form.bankLoginMethod" placeholder="请选择" style="width: 340px; margin: 0" filterable>
            <el-option v-for="item in loginMethodOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="filterUserName(form.yhName)" prop="userName">
          <input-common v-model:modelValue="form.userName" style="width: 340px"></input-common>
        </el-form-item>
        <el-form-item :label="filterText(form.yhName)" v-if="
          [
            '光大银行',
            '宁波银行',
            '建设银行',
            '齐鲁银行',
            '莱商银行',
            '江苏农村商业银行',
            '济宁银行',
            '河北农商银行',
            '承德银行',
            '衡水银行',
            '临商银行',
            '湖北银行',
            '廊坊银行',
            '河北银行',
            '桂林银行',
            '杭州银行',
            '威海市商业银行',
            '泰安银行'
          ].includes(form.yhName) ||
          (form.yhName == '交通银行' && form.bankLoginMethod == '客户号登录')
        " :prop="['河北银行'].includes(form.yhName) ? '' : 'czy'">
          <input-common v-model:modelValue="form.czy" style="width: 340px"></input-common>
        </el-form-item>
        <el-form-item label="登录密码:" prop="password">
          <input-common v-model:modelValue="form.password" style="width: 340px"></input-common>
        </el-form-item>
        <el-form-item label="证书密码：" v-if="form.yhName == '建设银行'" prop="certPass">
          <input-common v-model:modelValue="form.certPass" style="width: 340px"></input-common>
        </el-form-item>
        <el-form-item label="证书：" v-if="form.yhName == '建设银行'">
          <span style="
              color: #f56c6c;
              position: relative;
              right: 18%;
              font-size: 13px;
            ">*</span>
          <el-input style="width: 340px; margin-right: 10px; margin-left: -7px" v-model="path"></el-input>
          <input ref="excelUploadInput" style="display: none" type="file" @change="handleClick" />
          <el-button type="primary" @click="handleUpload()">请选择文件</el-button>
          <el-button type="primary" @click="uploadExcel" v-loading="daoruLoading">上传</el-button>
        </el-form-item>
        <span class="certificate" @click="getUrl" v-if="form.yhName == '建设银行'"><el-icon
            style="position: relative; top: 2px">
            <d-arrow-right /> </el-icon>点击查看证书获取方式</span>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveBank" size="small" :loading="saveLoading">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { findSubjects, wbList } from "@/api/subject";
import { saveBankName } from "@/api/bank";
import { COLLECT_BANK_OPTION } from "@/utils/commonData";
const { proxy } = getCurrentInstance();
const emit = defineEmits();
const dialogVisible = ref(false);
const comId = ref(0);
const comName = ref("");
const form = ref({ ids: {} });
const wbOptions = ref([]);
const subjectOptions = ref([]);
const checkDisabled = ref(false);
const bankBankList = COLLECT_BANK_OPTION;
const loginMethodOption = ref([
  {
    label: "客户号登录",
    value: "客户号登录",
  },
  {
    label: "用户名密码登录",
    value: "用户名密码登录",
  },
]);
const bankUrl = ref(
  "https://file.listensoft.net/mnt/v2/other/建设银行证书获取方式.pdf"
);
const ruleForm = ref();
const rules = ref({
  name: [{ required: true, message: "请输入名称", trigger: "blur" }],
  bankNo: [{ required: true, message: "请输入银行主账号", trigger: "blur" }],
  ids: [{ required: true, message: "请选择科目", trigger: "change" }],
  userName: [
    {
      required: true,
      message: "请输入登录名/客户号/操作员号/企业客户号",
      trigger: "blur",
    },
  ],
  bankNo: [{ required: true, message: "请输入银行主账号", trigger: "blur" }],
  password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
  czy: [
    {
      required: true,
      message: "请输入操作员/客户号/用户编号",
      trigger: "blur",
    },
  ],
  bankLoginMethod: [
    { required: true, message: "请选择登录方式", trigger: "change" },
  ],
  certPass: [{ required: true, message: "请输入证书密码", trigger: "change" }],
});
const excelUploadInput = ref();
const path = ref("");
const rawFile = ref("");
const daoruLoading = ref(false);
const saveLoading = ref(false);
onMounted(() => {
  wbList({}).then((res) => {
    wbOptions.value = res.data.data.list;
  });
});
const init = (row, item, type) => {
  form.value = { ids: {} };
  comId.value = row.comId;
  comName.value = row.name;
  findSubjects({ comId: row.comId, codes: ["1002", "1012"] }).then((res) => {
    if (res.data.msg == "success") {
      subjectOptions.value = res.data.data.listLow;
    }
  });
  if (item) {
    form.value = item;
    form.value.ids = {};
    form.value.name = type ? item.name : item.bankName;
    form.value.id = type ? item.id : item.bankId;
    form.value.ids.id = item.subjectId;
    form.value.ids.fzhsItemId = item.fzhsId;
    form.value.ids.bankLoginMethod = item.bankLoginMethod;
    path.value = item.cert;
    form.value.cert = item.cert;
    form.value.certPass = item.certPass;
    form.value.czy = item.czy;
    form.value.password = item.password;
    form.value.userName = item.userName;
    form.value.yhName = item.yhName;
    form.value.bankNo = item.bankNo;
  }
  dialogVisible.value = true;
};
//保存
const saveBank = () => {
  if (!form.value.ids) {
    ElMessage.warning("请选择科目");
    return;
  }
  if (form.value.yhName == "建设银行" && !form.value.cert) {
    proxy.$qzfMessage("请上传证书", 1);
    return;
  }
  let param = form.value;
  param.subjectId = form.value.ids.id;
  param.fzhsId = form.value.ids.fzhsItemId;
  param.comId = comId.value;
  ruleForm.value.validate((valid) => {
    if (valid) {
      saveLoading.value = true;
      saveBankName(param).then((res) => {
        saveLoading.value = false;
        if (res.data.msg == "success") {
          dialogVisible.value = false;
          ElMessage.success("操作成功");
          emit("success");
        }
      });
    }
  });
};
/* 建设银行上传证书start */
const handleUpload = () => {
  excelUploadInput.value.click();
};
const handleClick = (e) => {
  const files = e.target.files;
  rawFile.value = files[0]; // only use files[0]
  path.value = e.target.value; //路径
};
const uploadExcel = () => {
  if (!rawFile.value) {
    ElMessage.warning("请选择文件");
    return;
  }
  const files = rawFile.value;
  const isLt1M = files.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    ElMessage.warning("请不要上传大于1M的文件!");
    return;
  }
  const param = new FormData(); // 创建form对象
  param.append("upFile", rawFile.value, rawFile.value.name);
  param.append("path", "/mnt/bank/cert");
  param.append("name", new Date().getTime() + rawFile.value.name);
  daoruLoading.value = true;
  let config = { headers: { "Content-Type": "multipart/form-data" } };
  axios
    .post("https://public.listensoft.net/api/uploadFile", param, config)
    .then((res) => {
      daoruLoading.value = false;
      if (res.data.msg == "success") {
        ElMessage.success("上传成功");
        form.value.cert = "https://file.listensoft.net" + res.data.data.url;
      }
    })
    .catch((err) => { });
};
const getUrl = () => {
  window.open(bankUrl.value);
};
/* 建设银行上传证书end */
const elCheck = () => {
  checkDisabled.value = !checkDisabled.value;
};
const filterText = (type) => {
  if (type == "江苏农村商业银行" || type == "杭州银行") {
    return "操作员:";
  } else if (type == "承德银行" || type == '衡水银行') {
    return "企业客户号:";
  } else if (type == "临商银行" || type == "湖北银行" || type == '威海市商业银行' || type == '泰安银行') {
    return "用户编号:";
  } else if (type == "桂林银行") {
    return "用户号:";
  } else {
    return "客户号/操作员:";
  }
};
const filterUserName = (type) => {
  if (type == "江苏农村商业银行" || type == "湖北银行") {
    return "客户号:";
  } else if (type == "中国民生银行") {
    return "操作员号:";
  } else if (type == "杭州银行" || type == '威海市商业银行' || type == '泰安银行') {
    return "企业客户号:";
  } else {
    return "用户名:";
  }
};
defineExpose({
  init,
});
</script>

<style scoped lang="scss">
.sq_set {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  color: var(--themeColor, #17a2b8);
  display: block;
}

.iconfont {
  margin-right: 4px;
  font-size: 16px;
}

.certificate {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  margin-left: 109px;
  cursor: pointer;
  display: block;
  margin-top: -17px;
}
</style>
